$transition: 250ms ease;
$transition_slow: 500ms ease;

$primary_color: #303036;
$secondary_color: #FFFFFF;
$complementary_color_dark: #303036;
$complementary_color_light: #007B3F;
$danger: #FF7E6B;

:export {
    primaryColor: $primary_color;
    secondaryColor: $secondary_color;
    danger: $danger;
    complementaryColorDark: $complementary_color_dark;
    complementaryColorLight: $complementary_color_light;
}