.home_container_hero {
  display: flex;
  position: relative;
  height: var(--app-height);

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &_button {
    display: none;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    justify-content: flex-end;
    font-size: 1rem;
    font-weight: 400;

    @include size_M {
      display: flex;
    }

    div {
      display: flex;
      align-items: center;
      background-color: $complementary_color_dark;
      color: $secondary_color;
      padding: 10px 20px;
      border-radius: 5px;
    }
  }
}
