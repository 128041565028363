.accommodations_wrapper {
  color: $complementary_color_dark;

  .accommodations {
    display: flex;
    flex-direction: column;
    width: 100%;

    &_hero {
      display: flex;
      flex-direction: column;
      height: calc(var(--app-height));
      transition: 200ms;
      width: 100%;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        overflow: hidden;
      }

      &_title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        background-color: $primary_color;
        color: $secondary_color;

        h1 {
          text-align: start;
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
      }
    }

    &_img {
      display: flex;
      background-color: $complementary_color_dark;
      width: 100%;
    }

    &_container {
      h1 {
        text-align: start;
      }

      &_info {
        display: flex;
        flex-direction: column;
        padding: 2rem 0;
        gap: 6rem;

        &_text {
          display: flex;
          flex-direction: column;

          &_button {
            margin: 4rem auto 2rem auto;
            font-weight: 400;
            width: fit-content;

            div {
              display: flex;
              align-items: center;
              background-color: $complementary_color_dark;
              padding: 10px 20px;
              border-radius: 5px;

              color: $secondary_color;
            }
          }
        }

        &_details {
          display: grid;
          grid-template-columns: 1fr 1fr;

          @include size_S {
            grid-template-columns: 1fr;
            gap: 0rem;
          }

          gap: 4rem;
          margin-top: 6rem;

          p {
            color: $complementary_color_light;
            letter-spacing: 4px;
            margin-top: 0;
            font-weight: 600;
          }

          ul {
            margin-bottom: 3rem;

            li::marker {
              color: $complementary_color_light;
            }
          }
        }
      }
    }
  }
}
