#spa_about {
  background-color: $complementary_color_dark;
  color: $secondary_color;
  padding-top: 4rem;
}

#massages {
  background-color: $secondary_color;
  color: $primary_color;
  display: flex;
}

#wax {
  background-color: $primary_color;
  color: $secondary_color;
  display: flex;
  flex-direction: column;
}

#treatments {
  background-color: $secondary_color;
  color: $primary_color;
  display: flex;
}

.spa_about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 4rem;

  @include size_M {
    grid-template-columns: 1fr;
  }

  gap: 2rem;

  &_logo {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;

    h2 {
      margin-top: 0;
    }

    img {
      width: 40%;
    }
  }

  &_text {
    h2 {
      margin-top: 0;
    }
  }

  &_img {
    display: flex;

    &_rotate{
      transform: translateY(-1px) rotate(180deg);
    }
  }

  p {
    font-weight: 300;
  }
}

.spa_wrapper {
  color: $complementary_color_dark;

  .spa {
    display: flex;
    flex-direction: column;
    width: 100%;

    &_hero {
      display: flex;
      flex-direction: column;
      height: calc(var(--app-height));
      transition: 200ms;
      width: 100%;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        overflow: hidden;
      }

      &_title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        background-color: $primary_color;
        color: $secondary_color;

        h1 {
          text-align: start;
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
      }
    }

    &_container{
      margin-bottom: 4rem;
    }

    &_img {
      display: flex;
      background-color: $complementary_color_dark;
      width: 100%;
    }
  }
}

.offers_section {
  margin-bottom: 6rem;

  &_row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 4rem;

    @include size_M {
      grid-template-columns: 1fr;
    }

    gap: 2rem;

    p {
      text-transform: uppercase;
      color: $complementary_color_light;
      letter-spacing: 4px;
      margin-top: 0;
      font-weight: 600;
    }

    ul {
      margin-bottom: 3rem;

      li {
        margin-top: 10px;
        &::marker {
          color: $complementary_color_light;
        }
      }
    }
  }
}