.impressum {
  background-color: $secondary_color;
  color: $complementary_color_dark;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &_hero {
    display: flex;
    position: relative;

    img {
      height: calc(var(--app-height) - 8rem);

      @include size_S {
        height: calc(var(--app-height) - 6rem);
      }

      width: 100%;
      object-fit: cover;
      object-position: bottom;
    }
  }

  &_container {
    text-align: center;

    h3 {
      color: $complementary_color_light;
      text-align: center;
      text-transform: uppercase;

      &:not(:nth-of-type(1)) {
        margin-top: 3rem;
      }
    }
  }
}
