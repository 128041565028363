.caffe_wrapper {
  color: $complementary_color_dark;

  .caffe {
    display: flex;
    flex-direction: column;
    width: 100%;

    &_hero {
      display: flex;
      flex-direction: column;
      height: calc(var(--app-height));
      transition: 200ms;
      width: 100%;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        overflow: hidden;
      }

      &_title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        background-color: $primary_color;
        color: $secondary_color;

        h1 {
          text-align: start;
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
      }
    }

    &_img {
      display: flex;
      background-color: $complementary_color_dark;
      width: 100%;
    }

    &_container {
      h1 {
        text-align: start;
      }

      &_info {
        display: flex;
        flex-direction: column;
        padding: 2rem 0;
        gap: 6rem;

        &_text {
          display: flex;
          flex-direction: column;

          &_button {
            display: flex;
            justify-content: center;
            margin: 4rem auto;
            cursor: pointer;

            width: fit-content;
            position: relative;
            cursor: pointer;
            opacity: 1;

            &::after {
              content: '';
              position: absolute;
              bottom: -4px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: $complementary_color_dark;
              opacity: 1;
              transition: opacity 300ms, transform 300ms;
              transform: scale(0);

              @include size_XS {
                transform: scale(1);
              }

              transform-origin: center;
            }

            &:hover::after,
            &:focus::after {
              transform: scale(1);
            }
          }
        }

        &_details {
          margin-top: 4rem;
          margin-left: 2rem;

          p {
            color: $complementary_color_light;
            letter-spacing: 4px;
            margin-top: 0;
            font-weight: 600;
          }

          ul {
            margin-bottom: 3rem;

            li::marker {
              color: $complementary_color_light;
            }
          }
        }
      }
    }
  }
}

.caffe_container_info_text {
  &_list {
    padding-top: 2rem;
    font-weight: 600;
    & li {
      font-weight: 400;
    }
  }
}

.offhours {
  background: $danger;
  padding: 2rem;
  border-radius: 25px;
  max-width: 700px;
  width: 100%;
  margin: 4rem auto;
}
